<template>
  <div class="createTicketParent">
    <!-- <p>{{ ticketProcess.name }}</p> -->
    <form name="createticketForm" id="createticketForm">
      <table>
        <tr>
          <td class="leftColumn" >
            <label>{{ $t("ticket.subject") }}:* </label>
          </td>
          <td>
            <input id="subject" 
            class="input" 
            v-model="ticketProcess.subject" 
            required />
          </td>
        </tr>
      </table> 
      <div class="container customerContainer" >
      <table>
        <tr>
          <td>
            <label>
              <span>{{ $t("ticket.customer") }}</span>
            </label>
          </td>
        </tr>
        </table>
        <hr />
        <table>
        <tr>
          <td>
            <label>{{ $t("mail.mail") }}:* </label>
          </td>
          <td>
            <input
              type="email"
              class="input"
              id="sender"
              v-model="ticketProcess.ticketSender.emailAddress"
              @change="checkSenderEmailAdress"
              required
            />
          </td>
        </tr>
        <tr>
            <td class="leftColumn">
              <label>{{ $t("ticketMenu.firstName") }}: </label>
            </td>
            <td>
              <input
                type="text"
                class="input"
                id="firstName"
                v-model="customer.firstName"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>{{ $t("ticketMenu.name") }}: </label>
            </td>
            <td>
              <input
                type="text"
                class="input"
                id="name"
                v-model="customer.name"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>{{ $t("ticketMenu.organisation") }}: </label>
            </td>
            <td>
              <input
                type="text"
                class="input"
                id="organisation"
                v-model="customer.organisation"
              />
            </td>
          </tr>
         </table>
         <!-- <div class="customerSaveButtonContainer">
          <button  type="button" v-on:click="addCustomer()">
            Speichern
          </button>
         </div> -->
      </div>
      <table>
        <tr>
          <td class="leftColumn">
            <label>{{ $tc("ticket.pool", 1) }}:* </label>
          </td>
          <td>
            <select
              id="pool"
              class="input"
              v-model="ticketProcess.poolPrimaryKey"
              required
            >
              <option
                v-for="(pool, index) in pools"
                v-bind:index="index"
                v-bind:key="pool.primaryKey"
                :value="pool.primaryKey"
              >
                {{ pool.name }}
              </option>
            </select>
          </td>
        </tr>
        <br>
        <label>
          Inhalt:*
        </label>
      </table>
    </form>
    <new-ticket-input v-bind:ticketProcess="ticketProcess" v-bind:customer="customer" />
    <label>
        * Pflichtfelder
      </label>
  </div>
</template>

<script>
import poolFunctions from "@/functions/pool";
import NewTicketInput from "../components/NewTicketInput.vue";
import utilsFunctions from "@/functions/utils";
import customerFunctions from "../functions/customer";

export default {
  name: "CreateTicket",
  components: { NewTicketInput },
  data() {
    return {
      ticketProcess: {ticketSender: new Object()},
      pools: [],
      customer: {emailAddress: "", firstName: "", name: "", organisation: ""}
    };
  },
  methods: {
    async getPools() {
      this.pools = await poolFunctions.getPoolsByFilter("All", this.$store);

      if (this.pools == null) {
        // an error occured
      }
    },
    checkSenderEmailAdress(event) {
      utilsFunctions.checkEmailAdress(event.target, this.$t("mail.pleaseEnterAValidEmailAddress"));
      this.tryToLoadCustomer();
      console.log("checkSender Event:", event);
    },
    async tryToLoadCustomer() {
      // console.log("tryToLoadCustomer ticketProcess:", this.ticketProcess);
      let customer = null;
      if (this.ticketProcess && this.ticketProcess.ticketSender.emailAddress) {
        customer = await customerFunctions.getCustomerByEmailAdress(
          this.ticketProcess.ticketSender.emailAddress.toLowerCase()
        );
      } else {
        console.error(
          "tryToLoadCustomer ticketProcess or sender of it is null. ticketProcess:",
          this.ticketProcess
        );
      }

      // console.log("tryToLoadCustomer customer: ", customer);
      if (customer) {
        this.customer = customer;
      }
    }
  },
  beforeMount: function () {
    this.getPools();
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.createTicketParent {
  margin: 20px;
}

table {
  width: 100%;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  background: white;
}

select {
  width: 100%;
}

textarea {
  width: 100%;
}

.customerContainer {
  padding: 0.5em 0em 0.5em 0em; 
  margin-bottom: 0.5em;
}

.leftColumn {
  width:20%;
}

.customerSaveButtonContainer {
  justify-content: flex-end;
  display: flex;
  margin-top: 0.25em;
  margin-right: 0.25em;
}
</style>